<template>
    <div id="tabbar">
        <nut-drag
            class="floating-menu"
            :class="{ 'role-play': isRolePlay }"
            v-if="isAgent"
        >
            <nut-button type="primary" @click="backToOrgPosition"
                >返回原岗位</nut-button
            >
        </nut-drag>
        <!-- !isRolePlay &&  -->
        <nut-drag class="return-nova" v-if="http.isActRedirect.value">
            <nut-button type="primary" @click="http.backToACT()"
                >返回<br />小诺</nut-button
            >
        </nut-drag>
        <nut-tabbar
            class="nut-tabbar-root"
            v-if="showTabbarFlag"
            bottom
            :model-value="activeName"
            @tab-switch="clearStatus"
        >
            <nut-tabbar-item
                v-for="(item, index) in list"
                :name="item.name"
                :tab-title="item.title"
                :key="index"
                :to="item.url"
            >
                <template #icon="props">
                    <img :src="props.active ? item.activeIcon : item.icon" />
                </template>
            </nut-tabbar-item>
        </nut-tabbar>
    </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { TAB_ITEM_LIST } from "./config";
import { find, startsWith } from "lodash";
import { useRouter } from "vue-router";
import { DoctorService, HttpService, PersonService } from "@services";
import { Provide } from "@common";
import { showTabbarFlag } from "@components";
import { isAgent } from "./utils";

const router = useRouter();
const personSvc = Provide(PersonService);
const http = Provide(HttpService);
const currentPath = ref("");
interface TabItem {
    title: string;
    activeIcon: string;
    icon: string;
    name: string;
    url: string;
}
(async function initData(): Promise<void> {
    isAgent.value = await personSvc.isAgentPosition();
})();

function backToOrgPosition(): void {
    personSvc.switchAgent();
}
router.afterEach((to) => (currentPath.value = to.path));

const baseUrlRegExp = new RegExp(`^${import.meta.env.BASE_URL}`);
const list: TabItem[] = TAB_ITEM_LIST[personSvc.currentUser.roleCode];
const isRolePlay = personSvc.currentUser.isRolePlay;
const activeName = computed(() => {
    const res = find(list, (item) => {
        const url = location.pathname.replace(baseUrlRegExp, "");
        const itemUrl = item.url.replace(/^\//, "");
        return url && !itemUrl ? false : startsWith(url, itemUrl);
    });
    return (currentPath.value && res && res.name) || "/";
});
function clearStatus(item: TabItem): void {
    if (activeName.value !== item.name) {
        const doctorSvc = Provide(DoctorService);
        doctorSvc.clearHcpFilterStatus();
    }
}
</script>

<style lang="scss">
#tabbar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    > .floating-menu {
        right: 10px;
        bottom: $tabbar-height + 48px;
        width: auto;
        .nut-button {
            .nut-button__wrap {
                word-break: keep-all;
            }
        }
        &.role-play {
            bottom: $tabbar-height + 5px;
        }
    }
    > .return-nova {
        right: 80px;
        bottom: $tabbar-height + 20px;
        width: auto;
        .nut-button {
            position: fixed;
            height: 4.36rem;
            width: 4.36rem;
            z-index: 1000;
            background: #fff;
            box-shadow: 0px 0px 12px 0px rgba(0, 91, 150, 0.15);
            border-radius: 50%;
            font-size: 1.28rem;
            color: rgba(52, 170, 247, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            border: 1px solid rgba(52, 170, 247, 0.3);
            flex-direction: column;
            line-height: 1;
            margin-bottom: 5px;
            .nut-button__wrap {
                word-break: keep-all;
            }
        }
    }
    > .nut-tabbar-root {
        > .nut-tabbar {
            position: static;
            width: 100%;
            height: $tabbar-height;
            border-radius: $card-radius $card-radius 0 0;
            .nut-tabbar-item {
                color: $font-title-color;
                &__icon--unactive {
                    color: #adbcd6;
                }
                &_icon-box_nav-word {
                    font-size: 10px;
                    line-height: 1.4;
                }
                &_icon-box_icon {
                    > img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}
</style>
